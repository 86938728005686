// in src/Item.js
import {
  FunctionField,
  ReferenceField,
  useRecordContext,
  useShowContext,
} from "react-admin";

const CustomReferenceField = (props) => {
  //   const {
  //     //    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
  //     //    error, // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onError` side effect.
  //     //    isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
  //     //    isLoading, // boolean that is true until the record is available for the first time
  //     record, // record fetched via dataProvider.getOne() based on the id from the location
  //     //    refetch, // callback to refetch the record via dataProvider.getOne()
  //     //    resource, // the resource name, deduced from the location. e.g. 'posts'
  //   } = useRecordContext();
  const record = useRecordContext();
  let label = "-";
  if (props.field) {
    const _spltField = props.field.split(".");
    label = _spltField.reduce((_prev, _curr) => {
      return _prev[_curr] || "-";
    }, record);
    label = label !== "-" ? `@${label}` : label;
  }

  return <ReferenceField {...props} emptyText={label} />;
};

export default CustomReferenceField;
