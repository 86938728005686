import { Labeled, TextInput } from "react-admin";
import Autocomplete from "react-google-autocomplete";
import config from "../config/config";
import { useFormContext } from "react-hook-form";
import Item from "./layouts/Item";

const CustomPlacesInput = ({ source, required, translatable = false }) => {
  const { setValue } = useFormContext();
  //   const input2 = useController({ name: "lng" });
  return (
    <>
      <Item xs={6} sm={6} md={6}>
        <Autocomplete
          style={{ height: 34, width: "100%" }}
          apiKey={config.apiPlaces}
          onPlaceSelected={(place) => {
            setValue(
              "location",
              [place.geometry.location.lat(), place.geometry.location.lng()],
              { shouldDirty: true }
            );
            // Autofill address field/s
            // if (translatable){
            //   for (const locale of config.locales) {
            //     setValue("address." + locale, place.formatted_address, {
            //       shouldDirty: true,
            //     });
            //   }
            // }else {
            //   setValue("address", place.formatted_address, {
            //     shouldDirty: true,
            //   });
            // }
          }}
          options={{
            types: ["address"],
            componentRestrictions: { country: "es" },
          }}
        />
      </Item>
      <Item xs={6} sm={6} md={6}>
          <TextInput source={source} fullWidth required={required} />
      </Item>
    </>
  );
};

export default CustomPlacesInput;
