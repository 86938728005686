import {
  Datagrid,
  List,
  TextField,
  EditButton,
  useRecordContext,
  TextInput,
  DateField,
  ReferenceInput,
  ReferenceField,
  SelectInput,
  Show,
  BulkExportButton,
  TabbedShowLayout,
  Tab,
  DateTimeInput,
  usePermissions,
  SelectField,
  ChipField,
  ArrayField,
  SingleFieldList,
} from "react-admin";
import CustomPlacesInput from "../../components/CustomPlacesInput";

import Container from "../../components/layouts/Container";
import Row from "../../components/layouts/Row";
import Item from "../../components/layouts/Item";
import CustomNestedList from "../../components/lists/CustomNestedList";
import CustomEditForm from "../../components/forms/CustomEditForm";
import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomReferenceField from "../../components/fields/CustomReferenceField";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import CustomChipField from "../../components/fields/CustomChipField";

const BlockTitle = () => {
  const record = useRecordContext();
  return <span>Block {record ? `"${record.id}"` : ""}</span>;
};

const blockFilters = [
  <TextInput source="q" label="Search" alwaysOn />,
  <ReferenceInput
    label="User A"
    source="userA.id"
    reference="users"
    alwaysOn
  />,
  <ReferenceInput
    label="User B"
    source="userB.id"
    reference="users"
    alwaysOn
  />,
];

export const BlockList = () => {
  const { permissions } = usePermissions();
  const filter = {};
  const sort = { field: "date", order: "DESC" };
  return (
    <List filters={blockFilters} filter={filter} sort={sort}>
      <Datagrid rowClick="show" bulkActionButtons={<BulkExportButton />}>
        {permissions === "admin" ? <EditButton /> : null}
        {/* <TextInput source="id" /> */}
        {/*    */}
        <CustomReferenceField
          link="show"
          label="User A"
          source="userA.id"
          reference="users"
          field="userA.username"
        />
        <ReferenceField
          link="show"
          label="Institution"
          source="userA.institution.id"
          reference="institutions"
          emptyText="-"
        />
        <CustomReferenceField
          link="show"
          label="User B"
          source="userB.id"
          reference="users"
          field="userB.username"
        />
        <ReferenceField
          link="show"
          label="Institution"
          source="userB.institution.id"
          reference="institutions"
          emptyText="-"
        />
        <TextField source="questionnaire.id" emptyText="-" />
        {/* <ReferenceField
          link="show"
          label="Questionnaire"
          source="questionnaire.id"
          reference="questionnaires"
          emptyText="-"
        /> */}
        <ArrayField label="Code" source="answers" emptyText="-">
          <SingleFieldList linkType={false}>
              <ChipField source="code" size="small"/>
           </SingleFieldList>
        </ArrayField>
        {/* <ArrayField label="Value" source="answers" emptyText="-">
          <SingleFieldList linkType={false}>
              <ChipField source="value" size="small"/>
           </SingleFieldList>
        </ArrayField> */}
      </Datagrid>
    </List>
  );
};

export const BlockEdit = () => {
  return (
    <CustomEditForm title={<BlockTitle />} showDelete={true}>
      <Container>
          <Row>
            <CustomItemLabeled>
              <TextField source="id" emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <CustomReferenceField
                link="show"
                label="User A"
                source="userA.id"
                reference="users"
                field="userA.username"
              />
            </CustomItemLabeled>

            <CustomItemLabeled>
              <ReferenceField
                link="show"
                label="Institution"
                source="userA.institution.id"
                reference="institutions"
                emptyText="-"
              />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <CustomReferenceField
                link="show"
                label="User B"
                source="userB.id"
                reference="users"
                field="userB.username"
              />
            </CustomItemLabeled>

            <CustomItemLabeled>
              <ReferenceField
                link="show"
                label="Institution"
                source="userB.institution.id"
                reference="institutions"
                emptyText="-"
              />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <TextField source="questionnaire.id" emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
                <ArrayField label="Code" source="answers" emptyText="-">
                <SingleFieldList linkType={false}>
                    <ChipField source="code" size="small"/>
                </SingleFieldList>
              </ArrayField>
            </CustomItemLabeled>
            <CustomItemLabeled>
              <ArrayField label="Value" source="answers" emptyText="-">
                <SingleFieldList linkType={false}>
                    <ChipField source="value" size="small"/>
                </SingleFieldList>
              </ArrayField>
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <DateField source="createdAt" emptyText="-" />
            </CustomItemLabeled>
            <CustomItemLabeled>
              <DateField source="updatedAt" emptyText="-" />
            </CustomItemLabeled>
          </Row>
        </Container>
    </CustomEditForm>
  );
};

export const BlockCreate = () => {
  return (
    <CustomCreateForm>
      <Container>
        <Row>
          <Item>
            <ReferenceInput
              label="User A"
              source="userA.id"
              reference="users"
              fullWidth
              required
            />
          </Item>
          <Item>
            <ReferenceInput
              label="User B"
              source="userB.id"
              reference="users"
              fullWidth
              required
            />
          </Item>
        </Row>
      </Container>
    </CustomCreateForm>
  );
};

export const BlockShow = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="summary">
        <Container>
          <Row>
            <CustomItemLabeled>
              <TextField source="id" emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <CustomReferenceField
                link="show"
                label="User A"
                source="userA.id"
                reference="users"
                field="userA.username"
              />
            </CustomItemLabeled>

            <CustomItemLabeled>
              <ReferenceField
                link="show"
                label="Institution"
                source="userA.institution.id"
                reference="institutions"
                emptyText="-"
              />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <CustomReferenceField
                link="show"
                label="User B"
                source="userB.id"
                reference="users"
                field="userB.username"
              />
            </CustomItemLabeled>

            <CustomItemLabeled>
              <ReferenceField
                link="show"
                label="Institution"
                source="userB.institution.id"
                reference="institutions"
                emptyText="-"
              />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <TextField source="questionnaire.id" emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
                <ArrayField label="Code" source="answers" emptyText="-">
                <SingleFieldList linkType={false}>
                    <ChipField source="code" size="small"/>
                </SingleFieldList>
              </ArrayField>
            </CustomItemLabeled>
            <CustomItemLabeled>
              <ArrayField label="Value" source="answers" emptyText="-">
                <SingleFieldList linkType={false}>
                    <ChipField source="value" size="small"/>
                </SingleFieldList>
              </ArrayField>
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <DateField source="createdAt" emptyText="-" />
            </CustomItemLabeled>
            <CustomItemLabeled>
              <DateField source="updatedAt" emptyText="-" />
            </CustomItemLabeled>
          </Row>
        </Container>
      </Tab>
      <Tab label="reviews">
        <Row>
          <Item xs={12} sm={12} md={12}>
            <CustomNestedList resource="block-reviews" source={"block"}>
              <Datagrid rowClick="show" bulkActionButtons={false}>
                <ReferenceField
                  link="show"
                  source="user.id"
                  reference="users"
                  emptyText="-"
                />
                <ReferenceField
                  link="show"
                  source="questionnaire.id"
                  reference="questionnaires"
                  emptyText="-"
                />
                <ReferenceField
                  link="show"
                  source="report.id"
                  reference="reports"
                  emptyText="-"
                />
                <TextField source="answers" emptyText="-" />
                <TextField source="description" emptyText="-" />
              </Datagrid>
            </CustomNestedList>
          </Item>
        </Row>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
