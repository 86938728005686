import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import config from "../config/config";

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("auth");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
  getList: (resource, params) => {
    // console.log(config);
    // console.log(params);
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const _filter = aplanateObject(params.filter);
    const query = {
      ..._filter,
      page,
      limit: perPage,
      sort: order === "ASC" ? field : "-" + field,
    };
    // const query = {
    //     sort: JSON.stringify([field, order]),
    //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    //     filter: JSON.stringify(params.filter),
    // };
    const url = `${config.apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => {
      //aplanateObjects(json.rows);
      return {
        data: json.rows,
        total: json.count,
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${config.apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      //aplanateObject(json);
      return {
        data: json,
      };
    }),

  getMany: (resource, params) => {
    const query = {
      ids: params.ids,
    };
    const url = `${config.apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      //aplanateObjects(json.rows);
      return {
        data: json.rows,
      };
    });
  },

  getManyReference: (resource, params) => {
    // const { page, perPage } = params.pagination;
    // const { field, order } = params.sort;
    const query = {};
    // const query = {
    //     sort: JSON.stringify([field, order]),
    //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    //     filter: JSON.stringify({
    //         ...params.filter,
    //         [params.target]: params.id,
    //     }),
    // };
    const url = `${config.apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      //aplanateObjects(json.rows);
      return {
        data: json,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      };
    });
  },

  create: async (resource, params) => {
    const _body = aplanateObject(params.data);
    // Upload Files
    return httpClient(`${config.apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify({
        ..._body,
      }),
    }).then(({ json }) => ({
      data: { ..._body, id: json.id },
    }));
  },

  update: async (resource, params) => {
    const _body = aplanateObject(params.data);
    return httpClient(`${config.apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify({
        ..._body,
      }),
    }).then(({ json }) => {
      //aplanateObject(json);
      return { data: json };
    });
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${config.apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      //aplanateObject(json);
      return { data: json };
    });
  },

  delete: (resource, params) =>
    httpClient(`${config.apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => {
      //aplanateObject(json);
      return { data: json };
    }),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${config.apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      //aplanateObject(json);
      return { data: json };
    });
  },

  uploadFile: async (file, type = "USER_CONTENT") => {
    let json = {};
    let body = new FormData();
    body.append("file", file);
    body.append("type", type);
    const response = await httpClient(`${config.apiUrl}/resources`, {
      method: "POST",
      body: body,
    });
    if (response.status === 201) json = JSON.parse(response.body);
    return json.path;
  },
};

const aplanateObjects = (data) => {
  data.map((_d) => {
    aplanateObject(_d);
  });
};

const aplanateObject = (data) => {
  if (data && typeof data === "object") {
    Object.keys(data).forEach((_d) => {
      if (
        data[_d] &&
        typeof data[_d] === "object" &&
        data[_d].id !== undefined
      ) {
        if (data[_d].id) {
          data[_d] = data[_d].id;
        } else {
          delete data[_d];
        }
      } else if (data[_d] && Array.isArray(data[_d]) && data[_d].length) {
        data[_d].forEach((_o, i) => {
          if (_o && typeof _o === "object" && _o.id !== undefined) {
            if (_o.id) {
              data[_d][i] = _o.id;
            } else {
              delete [_d][i];
            }
          }
        });
      }
    });
  }

  return data;
};

export default dataProvider;
